@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

*{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    
    text-decoration: none;
    /* font-size: 32px; */
}

html {
    /* 1rem = 10px */
    font-size: 62.5%;
}

html,
body {
/* height: 100%; */
font-display: swap;
text-rendering: optimizeLegibility;
-webkit-font-smoothing: antialiased;
}
  
a, a:hover, a:visited, a:active{
    text-decoration: none;
    /* color: red;     */
}

.localidade{
    font-size: 17px;
}

.menuItem {
    color: #dad2d8;
}

.testeSize{
    height: 1100px;
}